<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 my-visits-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="reception-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_my-visits'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("my-visits", "visit", "visit") }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <!-- Host details -->
    <!-- Title -->
    <ul class="clebex-item-section pill no-space" v-if="visit">
      <li class="clebex-item-section-label-inner">
        <span class="highlight">{{
          displayLabelName("my-visits", "visit", "visits-details")
        }}</span>
      </li>
    </ul>
    <ul class="clebex-item-section" v-if="visit">
      <!-- Visit datetime from -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "from")
            }}</label>
            <p class="text-label">
              {{ dateFormat(visit.datetime_from) }}
            </p>
          </div>
        </div>
      </li>
      <!-- Visit datetime to -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "to")
            }}</label>
            <p class="text-label">
              {{ dateFormat(visit.datetime_to) }}
            </p>
          </div>
        </div>
      </li>
      <!-- First name, last name -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "host")
            }}</label>
            <p class="text-label">
              {{ `${visit.host.first_name} ${visit.host.last_name}` }}
            </p>
          </div>
        </div>
      </li>
      <!-- Email -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "email")
            }}</label>
            <p class="text-label">
              {{ visit.host.email }}
            </p>
          </div>
        </div>
      </li>
      <!-- Company -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "company")
            }}</label>
            <p class="text-label">
              {{ visit.host.company }}
            </p>
          </div>
        </div>
      </li>
      <!-- Notes -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "notes")
            }}</label>
            <p class="text-label">
              {{ visit.notes }}
            </p>
          </div>
        </div>
      </li>
    </ul>
    <!-- End of host details -->

    <!-- Meeting details  -->
    <ul
      class="clebex-item-section pill no-space"
      v-if="visit && visit.booking && Object.keys(visit.booking).length"
    >
      <li class="clebex-item-section-label-inner">
        <span class="highlight">{{
          displayLabelName("my-visits", "visit", "meeting-details")
        }}</span>
      </li>
    </ul>
    <ul
      class="clebex-item-section no-top-margin"
      v-if="visit && visit.booking && Object.keys(visit.booking).length"
    >
      <!-- Meeting Subject  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "meeting-subject")
            }}</label>
            <p class="text-label">{{ visit.booking.subject }}</p>
          </div>
        </div>
      </li>

      <!-- End Meeting Subject  -->

      <!-- Meeting Date from  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "meeting-from")
            }}</label>
            <p class="text-label">
              {{ dateFormat(visit.booking.datetime_from) }}
            </p>
          </div>
        </div>
      </li>
      <!-- End Meeting Date from  -->

      <!-- Meeting Date to  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "meeting-to")
            }}</label>
            <p class="text-label">
              {{ dateFormat(visit.booking.datetime_to) }}
            </p>
          </div>
        </div>
      </li>
      <!-- End Meeting Date from  -->

      <!-- Meeting Resource  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "meeting-resource")
            }}</label>
            <p class="text-label">
              {{ visit.booking.resource }}
            </p>
          </div>
        </div>
      </li>
      <!-- End Meeting Resource  -->

      <!-- Meeting Organizer  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "meeting-organizer")
            }}</label>
            <p class="text-label">
              {{ visit.booking.organizer }}
            </p>
          </div>
        </div>
      </li>
      <!-- End Meeting Organizer  -->

      <!-- Meeting Description  -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "meeting-description")
            }}</label>
            <p class="text-label">
              {{ visit.booking.description }}
            </p>
          </div>
        </div>
      </li>
      <!-- Meeting Description  -->

      <!-- Meeting Attendies  -->
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="visit.booking.attendies.length"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("my-visits", "visit", "meeting-attendees")
            }}</label>
            <p class="text-label">
              {{
                visit.booking.attendies
                  .map(item => `${item.first_name} ${item.last_name}`)
                  .join(", ")
              }}
            </p>
          </div>
        </div>
      </li>
      <!-- End Meeting Attendies  -->
    </ul>
    <!-- End Meeting details  -->

    <!-- History details -->
    <ul
      class="clebex-item-section pill no-space"
      v-if="visit && visit.history && Object.keys(visit.history).length"
    >
      <li class="clebex-item-section-label-inner">
        <span class="highlight">{{
          displayLabelName("my-visits", "visit", "history-details")
        }}</span>
      </li>
    </ul>

    <ul
      class="clebex-item-section no-top-margin"
      v-if="visit && visit.history && Object.keys(visit.history).length"
    >
      <li
        v-for="(history, index) in visit.history"
        :key="index"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                class="history-status-icon"
                :icon="getHistoryStatus(history.status_id).icon"
              />
            </span>
          </span>
          <dl class="clebex-item-dl no-left-padding">
            <dt class="clebex-item-dt">
              <div class="clebex-item-parent-list">
                <p class="parent-name">
                  {{
                    displayLabelName(
                      "my-visits",
                      "visit",
                      getHistoryStatus(history.status_id).label
                    )
                  }}
                </p>
              </div>
            </dt>
            <dd class="clebex-item-dd">
              {{ dateFormat(history.datetime) }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <!-- End History details -->

    <!-- QrCode -->
    <ul class="clebex-item-section" v-if="visit && visit.qrcode">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <img :src="visit.qrcode" alt="QrCode" />
          </div>
        </div>
      </li>
    </ul>
    <!-- End qrCode -->
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";
import { visitStatuses } from "@/services/visitor-management";

export default {
  name: "Receptions",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      visitorStatuses: [
        {
          id: visitStatuses.CREATED,
          icon: "#cx-vis1-expected",
          label: "created"
        },
        {
          id: visitStatuses.CHECKED_IN,
          icon: "#cx-vis1-check-in",
          label: "checked-in"
        },
        {
          id: visitStatuses.CHECKED_OUT,
          icon: "#cx-vis1-check-out",
          label: "checked-out"
        },
        {
          id: visitStatuses.ABSENT,
          icon: "#cx-vis1-paused",
          label: "absent"
        }
      ]
    };
  },
  created() {
    this.getVisit(this.visitId);
  },
  watch: {
    visitId: {
      handler(value) {
        if (typeof value !== "undefined") {
          this.getVisit(this.visitId);
        }
      }
    }
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("myVisits", ["visit"]),
    visitId() {
      const { visitId } = this.$route.params;
      return visitId;
    }
  },
  methods: {
    ...mapActions("myVisits", ["getVisit"]),
    dateFormat(date) {
      return formatDate(
        date,
        `${this.globalDateFormat} ${this.globalTimeFormat}`
      );
    },
    getHistoryStatus(id) {
      return this.visitorStatuses.find(status => status.id === id);
    }
  }
};
</script>
