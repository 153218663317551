export const visitStatuses = {
  CREATED: 1,
  CHECKED_IN: 2,
  CHECKED_OUT: 3,
  ABSENT: 4
};

export const visitStatusLabels = {
  [visitStatuses.CREATED]: "created",
  [visitStatuses.CHECKED_IN]: "checked-in",
  [visitStatuses.CHECKED_out]: "checked-out",
  [visitStatuses.ABSENT]: "absent"
};

/**
 * Default value for VM table if field is not set or empty. This could be anything "-" or "&nbsp;" etc.
 */
export const tableFieldDefaultValue = "-";
